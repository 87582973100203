<template>
  <div>
    <div class="bg" />
    <div class="container">
      <img src="@/assets/user-photo.png" class="photo" />
      <div class="name">{{ userposition?userposition:'未绑定负责人' }}</div>
      <div class="content">
        <div class="username">
          <img src="@/assets/usericon.png" class="icon" />
          <div class="text">{{username}}, 您好</div>
        </div>
        <div class="message">您是否想要成为"{{client_name}}"的负责人?</div>
        <div class="button primary" @click="submit">确定</div>
        <div class="button mt" @click="cancel">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserinfo } from '@/utils';
import { Toast, Dialog } from 'vant';
import qs from 'qs';
export default {
  async created() {
    let { id, name } = this.$route.query;
    let { username, clientname } = getUserinfo();
    this.id = id;
    this.client_id = name;
    this.client_name = name;
    this.username = username;
    this.userposition = clientname;

    let { data } = await this.axios.post(
      '/ctid/tlService/getdoorname',
      qs.stringify({ site_code: this.id, client_id: this.client_id })
    );
 
    if (data.flag === '0') {
      this.client_name = data.data.doorname;
     }
  },
  data() {
    return {
      id: '',
      client_id: '',
      client_name: '',
      username: '',
      userposition: ''
    };
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    async submit() {
      let { data } = await this.axios.post(
        '/ctid/tlService/bangding',
        qs.stringify({
          stie_code: this.id,
          client_id: this.client_id
        })
      );
      if (data.flag === '0') {
        Toast('提交成功!');
        this.$router.replace('/user/my');
      }else if(data.flag === '2'){
        Toast('提交成功!');
        this.$router.replace('/position/input_pass?stie_code=' + this.id +'&client_id=' + this.client_id);
      }else {
        Dialog({ message: data.message });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.photo {
  width: 166px;
  height: 189px;
  display: block;
  margin: 66px auto 0;
}
.content {
  margin-top: 66px;
}
.name {
  text-align: center;
  color: #fff;
  font-size: 44px;
  line-height: 60px;
  margin-top: 14px;
}
.username {
  height: 64px;
  line-height: 64px;
  overflow: hidden;
  .icon {
    width: 64px;
    height: 64px;
    float: left;
    display: block;
  }
  .text {
    margin-left: 22px;
    float: left;
  }
}
.message {
  overflow: hidden;
  margin: 100px auto;
  text-align: center;
}
</style>
